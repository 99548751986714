// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 105%;
    background-color: rgb(157, 38, 255);
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 0;
    position: absolute;
    z-index: 1;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
    }`, "",{"version":3,"sources":["webpack://./src/Pages/Components/ToolTip.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,mCAAmC;IACnC,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,UAAU;IACV,kBAAkB;IAClB,UAAU;IACV,UAAU;IACV,SAAS;IACT,2BAA2B;IAC3B,UAAU;IACV,wBAAwB;AAC5B;;AAEA;IACI,mBAAmB;IACnB,UAAU;IACV","sourcesContent":[".tooltip {\n    position: relative;\n    display: inline-block;\n    cursor: pointer;\n}\n\n.tooltip .tooltiptext {\n    visibility: hidden;\n    width: 105%;\n    background-color: rgb(157, 38, 255);\n    color: #fff;\n    text-align: center;\n    border-radius: 5px;\n    padding: 0;\n    position: absolute;\n    z-index: 1;\n    bottom: 0%;\n    left: 50%;\n    transform: translateX(-50%);\n    opacity: 0;\n    transition: opacity 0.3s;\n}\n\n.tooltip:hover .tooltiptext {\n    visibility: visible;\n    opacity: 1;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
