import React, { useState, useEffect } from 'react';
import "./ImageLink.css"

/* I like making title a requirement here. It sets a prescedence that all pictures like this have hover text */
export default function ImageLink({ children, name, altText, title }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [clicked, setClicked] = useState(false)

    const images = {
      flashcards: {info:"Doodle on a flashcard. I always had stacks on me bc i was studying. I've got a beaten up doodle stack smwhere.", img:require('../../Images/art/2017/DPl_u40VQAEabE8.jfif'), date:'2017'},
      cheerupchump: {info:'I LOVE DOING THIS. There\'s NO way I can say "[puts on clown outfit] cheer up!" and have it be the same', img:require('../../Images/art/2017/DPgi_2SWAAE6wrg.jfif'), date:'2017'},
      snark: {info:'A stape of my character used to be curved hash lines between their lines. Im actually not sure when or why I stopped doing that it was cool lookin. SNARK', img:require('../../Images/art/2017/DR2KbQEU8AACOWL.jfif'), date:'2017'},
      mspaint: {info:'I remember this time SUPER fondly bc of how many hours I could dig into my hobbies. It\'s also when I started living on my own :] oh tha memories', img:require('../../Images/art/2017/20170525-190807-867895085358170116-867895074545446917-rxAMBh2n.jpg'), date:'2017'},
      crack_bunny: {info:'LOL i got dat one a lot',img:require('../../Images/art/2018/das me.PNG'), date:'2018'},
      super_old_luna: {info:'A lot of my drawings of him are in mspaint because I worked 12 hour shifts and wanted to draw with my friends <:J', img:require('../../Images/art/2017/20170525-190807-867895085358170116-867895074545446917-rxAMBh2n.jpg'), date:"2017"},
      caught_rabbit: {info:'same difference LOL', img:require('../../Images/art/2017/20170917-190727-909569519244292100-909569506778869760-NV1LDxMt.jpg'), date:'2017'},
      bunny_tail: {info:'QUICK! WRAP THEM AROUND DA EARTH.\nlunatic: NOOOO DONT WRAP ME AROUND DA EARTHH', img:require('../../Images/art/2018/DUgMG9ZUQAAtj7J.jfif'), date:'2018'},
      lunatic_man: {info:'I made like, 7 vrchat models and dis was one of em LOL. I think the original model was from a dreamcast game. I think vrchat was when he started being on the purple spectrum.', img:require('../../Images/art/2018/DZGbt5vXUAAtZyi.jfif'), date:'2018'}
    }

    useEffect(() => {
      // Check if the item has been clicked before
      const isClicked = localStorage.getItem('clickedText'+name);
      if (isClicked) {
          setClicked(true);
      }
    }, []);

    const openModal = () => {
      setIsModalOpen(true);
      setClicked(true);
      localStorage.setItem('clickedText'+name, 'true');
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
    


    return (
      <span>
          <span className="imagelink" onClick={openModal} style={{ color: clicked ? 'yellow' : 'rgb(222, 197, 255)'}}>{children}</span>

        {isModalOpen && (
          <span className="modal" onClick={closeModal}>
            <div style={{position: "absolute", top: "0", fontSize: ".7em", width: "40em", textAlign: "center"}}>{images[name].info}</div>
            <span className="close" onClick={closeModal}>&times;</span>
            <img className="modal-content" src={images[name].img} alt={altText} text={images[name].date} />
          </span>
        )}
      </span>
    );
  }
  
