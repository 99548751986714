// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

/* Styling the thumbnail image */
.thumbnail {
  width: 3em;
  margin-left: 2vh;
  margin-right: 2vh;
  padding: 0px;
  cursor: pointer;
  transition: transform 0.2s;
}

.thumbnail:hover {
  transform: rotate(10deg);
}

/* Modal background */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 9, 54, 0.699); /* Dark tint */
}

/* Close button styling */
.close {
  position: absolute;
  top: 20px;
  right: 35px;
  color: white;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* Full image styling */
.modal-content {
  max-width: 80%;
  max-height: 80%;
  border: 2px solid white;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Components/Thumbnail.css"],"names":[],"mappings":";;AAEA,gCAAgC;AAChC;EACE,UAAU;EACV,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;EACZ,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA,qBAAqB;AACrB;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,UAAU;EACV,OAAO;EACP,MAAM;EACN,WAAW;EACX,YAAY;EACZ,wCAAwC,EAAE,cAAc;AAC1D;;AAEA,yBAAyB;AACzB;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,eAAe;AACjB;;AAEA;;EAEE,WAAW;EACX,qBAAqB;EACrB,eAAe;AACjB;;AAEA,uBAAuB;AACvB;EACE,cAAc;EACd,eAAe;EACf,uBAAuB;AACzB","sourcesContent":["\n\n/* Styling the thumbnail image */\n.thumbnail {\n  width: 3em;\n  margin-left: 2vh;\n  margin-right: 2vh;\n  padding: 0px;\n  cursor: pointer;\n  transition: transform 0.2s;\n}\n\n.thumbnail:hover {\n  transform: rotate(10deg);\n}\n\n/* Modal background */\n.modal {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: fixed;\n  z-index: 1;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(10, 9, 54, 0.699); /* Dark tint */\n}\n\n/* Close button styling */\n.close {\n  position: absolute;\n  top: 20px;\n  right: 35px;\n  color: white;\n  font-size: 40px;\n  font-weight: bold;\n  cursor: pointer;\n}\n\n.close:hover,\n.close:focus {\n  color: #bbb;\n  text-decoration: none;\n  cursor: pointer;\n}\n\n/* Full image styling */\n.modal-content {\n  max-width: 80%;\n  max-height: 80%;\n  border: 2px solid white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
