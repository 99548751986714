import { useState, useEffect } from 'react';

const UseSpringyValue = (initialValue = 1, spring, damping, delay = 0) => {
  const [value, setValue] = useState(initialValue);
  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    let animationFrameId;
    let lastTime = performance.now();
    let timeoutId;

    let velocity = 0;
    let position = initialValue;

    const animate = (time) => {
      const deltaTime = time - lastTime;
      lastTime = time;

      const target = 1;
      const force = -spring * (position - target);
      velocity += force;
      velocity *= damping;
      position += velocity;

      setValue(position);

      animationFrameId = requestAnimationFrame(animate);
    };

    if (startAnimation) {
        if (delay > 0) {
            timeoutId = setTimeout(() => {
            animationFrameId = requestAnimationFrame(animate);
            }, delay);
        } else {
            animationFrameId = requestAnimationFrame(animate);
        }
    
        return () => {
            cancelAnimationFrame(animationFrameId);
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }
  }, [initialValue, delay, startAnimation]);

  const start = () => {
    setStartAnimation(true);
  };

  return [value, start];
};

export default UseSpringyValue;