import React from 'react';
import './ToolTip.css'; // Import the CSS

const Tooltip = ({ children, text }) => {
  return (
    <span className="tooltip">
      {children}
      <span className="tooltiptext">{text}</span>
    </span>
  );
};

export default Tooltip;