// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

/* Styling the thumbnail image */
.videolink {
  width: 3em;
  padding: 0px;
  cursor: pointer;
  transition: transform 0.2s;
}

.imagelink:hover {
  transform: rotate(10deg);
}`, "",{"version":3,"sources":["webpack://./src/Pages/Components/VideoLink.css"],"names":[],"mappings":";;AAEA,gCAAgC;AAChC;EACE,UAAU;EACV,YAAY;EACZ,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":["\n\n/* Styling the thumbnail image */\n.videolink {\n  width: 3em;\n  padding: 0px;\n  cursor: pointer;\n  transition: transform 0.2s;\n}\n\n.imagelink:hover {\n  transform: rotate(10deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
