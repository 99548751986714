import React, { useState } from 'react';
import "./Thumbnail.css"

/* I like making title a requirement here. It sets a prescedence that all pictures like this have hover text */
export default function Thumbnail({ children, filmBackgroundColor, thumbnailSrc, fullImageSrc, altText, title, thumbnailClass }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
    

    return (
      <span>
          <img
            src={thumbnailSrc}
            alt={altText}
            className={thumbnailClass}
            onClick={openModal}
            style={{cursor:'pointer'}}
          />
  
        {isModalOpen && (
            <span className="modal" onClick={closeModal} style={{backgroundColor: filmBackgroundColor}}>
              <span style={{position: 'fixed', top: '1em'}}>{children}</span>
              <img className="modal-content" src={fullImageSrc} alt={altText} text={title} />
            </span>
        )}
      </span>
    );
  }
  
