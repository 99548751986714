import React, { useState, useEffect } from 'react';
import './Home.css'
import { Link } from 'react-router-dom';
import Thumbnail from "./Components/Thumbnail"
import ImageLink from "./Components/ImageLink"
import VideoLink from "./Components/VideoLink"
import Tooltip from './Components/ToolTip';
import UseSpringyValue from "./Components/UseSpringyValue"


export default function Home() {
    const [iStayNow, setIStayNow] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const handleFullscreenChange = () => {
        setIsFullscreen(
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
            ? true
            : false
        );
    };

    useEffect(() => {
        document.addEventListener('fullscreenchange', handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
        document.addEventListener('mozfullscreenchange', handleFullscreenChange);
        document.addEventListener('MSFullscreenChange', handleFullscreenChange);

        // Check initially if the document is already in fullscreen mode
        handleFullscreenChange();

        return () => {
        document.removeEventListener('fullscreenchange', handleFullscreenChange);
        document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
        document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
        document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
        const scrolledToBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight;
        if (scrolledToBottom){
            setIStayNow(scrolledToBottom);
        }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [googleeeye_animate1_x, googlee_start1_x] = UseSpringyValue(0, 0.05, 0.90)
    const [googleeeye_animate1_y, googlee_start1_y] = UseSpringyValue(0, 0.05, 0.90, 15)
    const [googleeeye_animate2_x, googlee_start2_x] = UseSpringyValue(0, 0.05, 0.90, 60)
    const [googleeeye_animate2_y, googlee_start2_y] = UseSpringyValue(0, 0.05, 0.90, 75)
    const googeelee_start = () => {
        googlee_start1_x();
        googlee_start1_y();
        googlee_start2_x();
        googlee_start2_y();
    }
    
    return (
    <div className="root">
        <div class="container">
            <div id="titlebox">
                <div class="title" style={{zIndex: "1"}}>Bunny Platoon</div>
                <div class="title" style={{zIndex: "1"}}>Bunny Platoon</div>
                <div class="title" style={{zIndex: "1"}}>Bunny Platoon</div>
                <div class="title" style={{zIndex: "1"}}>Bunny Platoon</div>
            </div>
            <div style={{display: "flex", height: "870px"}}>
            <div class="box" style={{position: "relative", display: "block", height: "100%", padding: "20px", paddingRight: "45px", margin: "0px", boxSizing: "border-box"}}>
                <div style={{position: "relative", top: "10px", textAlign: "center"}}>F11 for better viewing. Make this text come out of a dialog box on the luna</div> 
                <img style={{float: "left", width: "50%"}} src={require('../Images/art/2024/hyowdy.png')}/>
                <br/><br/><br/>
                {"HYOWDY I'm "}
                <span style={{position: "relative", cursor: 'pointer'}} onClick={googeelee_start}>
                    <p style={{margin: '0', padding: '0', display: "inline-block", cursor: 'pointer'}}>Lunatoony</p>
                    <span className="googeelee" style={{transform: `scaleX(${googleeeye_animate1_x})`, transform: `scaleY(${googleeeye_animate1_y})`, right: "56px", top: "0"}}><span className="dot"/></span>
                    <span className="googeelee" style={{transform: `scaleX(${googleeeye_animate2_x})`, transform: `scaleY(${googleeeye_animate2_y})`, right: "33px", top: "0"}}><span className="dot"/></span>
                </span>
                and this is a website!<br/><br/>
                I love spending all day on my computer making things to entertain myself. I also feel sad when I think how most of it doesn't have a <a href="/games/house">home.</a>
                <br/><br/>
                I also love the experience of digging through peoples personal websites with all my heart.
                <br/><br/><br/><br/><br/>
                Please explore! Goodluck!
                <br/>
                <span style={{fontSize: "16px"}}>- Tuna Loony, crazed fish maniac</span>
                <img style={{position: "absolute", bottom: "0", right: "0"}} src={require('../Images/art/2022/bunnydance.gif')}/>
                <br/><br/><br/>
            </div>
            <div class="box" style={{position: "relative", width: "50%", height: "100%", marginRight: "20px", display: "block", boxSizing: "border-box"}}>
                <br/><br/>
                <img style={{position: "absolute", bottom: "0", right: "0"}} src={require('../Images/art/2022/bunnydance.gif')}/>
                <ul style={{cursor: 'pointer', fontSize: "32px", padding: '0', paddingLeft: '10px', margin: '0'}}>
                    <li><Link to="/hotsauce">HOT SAUCE</Link></li>
                    <br/>
                    <li><Link to="/stupid">STUPID</Link></li>
                    <br/>
                    <li><Link to="/thepit">THE TWIG</Link></li>
                    
                </ul>
            </div>
            </div>
            {/*<span style={{fontSize: '18px', color: "white"}}>Hi I love entertaining and my goal is to make a game with a piece of my soul in it.</span>
        </div>
        <marquee class="train" style={{top: "113vh"}}>
          <a href="https://cinni.net" title="I love their art somuch. The way they blend art with the page is SO cool and im jealous." target="_blank"><img src={require("../Images/buttons/cinni.gif")} alt="cinni"/></a>
          <a href="https://inkcaps.neocities.org" title="I love their pre-home page bc it gives me hypnospace fungi scene cozy vibes." target="_blank"><img src="https://inkcaps.neocities.org/home/inkcapsbutton.gif" alt="inkcaps"/></a>
          <a href="https://hunipyon.neocities.org" title="The skewed images are really funny LOL reading them gush about their favorite stuff is fun" target="_blank"><img src={require("../Images/buttons/hunipyon.gif")}/></a>
          <a href="https://churupan.neocities.org" title="" target="_blank"><img src="https://file.garden/ZX1fii8pMXxJ-5zC/churupan%20assets/churupanbutton.gif"/></a>
          <a href="https://www.lejlart.com/apple.html" title="Cute! I love the glittery skull vibe [: looking at the pixel adopts gives me a good vibe." target="_blank"><img src="https://www.lejlart.com/flist/ad_skullsprinkles.gif" alt="appledust"/></a>
          <a href="https://isopodz.neocities.org" title="Their posts are a fun lil read! Its under construction so there's not a lot going on buh i like that" target="_blank"><img src="https://isopodz.neocities.org/buttons/littleguybutton.jpg" alt="isopodz"/></a>
          <a href="https://horg.com" target="_blank">horg</a>
        </marquee>
        {/*<Musicplayer source="https://audio.jukehost.co.uk/r845eWR171ljGuxat986bKmKcxErntKT"/>*/}
        </div>
        <img src={require('../Images/art/2024/iseeyouistaynow.png')} alt="hweeweeeaaa aaa" className={`sticky-container ${iStayNow ? 'sticky' : ''}`} />
    </div>
    )
}
