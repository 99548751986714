import React, { useState, useEffect, useRef } from 'react';
import "./VideoLink.css"

/* I like making title a requirement here. It sets a prescedence that all pictures like this have hover text */
export default function ImageLink({ children, name, title }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [clicked, setClicked] = useState(false)

    const videos = {
      badump: require('../../Videos/boofiest boober ta da ball.mov')
    }

    const videoRef = useRef(null);

    const handlePlay = () => {
      videoRef.current.play();
    };

    const handlePause = () => {
      videoRef.current.pause();
    };

    useEffect(() => {
      // Check if the item has been clicked before
      const isClicked = localStorage.getItem('clickedText'+name);
      if (isClicked) {
          setClicked(true);
      }
    }, []);

    const openModal = () => {
      setIsModalOpen(true);
      setClicked(true);
      localStorage.setItem('clickedText'+name, 'true');
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
    


    return (
      <span>
          <span className="videolink" onClick={openModal} style={{ color: clicked ? 'yellow' : 'rgb(222, 197, 255)'}}>{children}</span>

        {isModalOpen && (
          <span className="modal" onClick={closeModal}>
            <video ref={videoRef} width="600" controls>
              <source src={videos[name]} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </span>
        )}
      </span>
    );
  }
  
